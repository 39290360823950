@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Palanquin:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Palanquin:wght@100;200;300;400;500;600;700&display=swap");


@tailwind base;
@tailwind components;
@tailwind utilities;


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  
  @layer utilities {
    .padding {
      @apply sm:px-16 px-8 sm:py-24 py-12;
    }
  
    .padding-x {
      @apply sm:px-16 px-8;
    }
  
    .padding-y {
      @apply sm:py-24 py-12;
    }
  
    .padding-l {
      @apply sm:pl-16 pl-8;
    }
  
    .padding-r {
      @apply sm:pr-16 pr-8;
    }
  
    .padding-t {
      @apply sm:pt-24 pt-12;
    }
  
    .padding-b {
      @apply sm:pb-24 pb-12;
    }
  
    .info-text {
      @apply font-montserrat text-slate-gray text-lg leading-7;
    }
  }